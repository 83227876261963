import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const $ = (s) => document.querySelector(s);

ScrollTrigger.create({
  trigger: "#entry",
  onLeave: () => {
    if (window.runGoL) {
      $("#game-of-life").classList.add("off");
      setTimeout(() => (window.runGoL = false), 500);
    }
  },
  onEnterBack: () => {
      setTimeout(() => {
        $("#game-of-life").classList.remove("off");
        window.restart()
      }, 500);
  },
});

ScrollTrigger.create({
  trigger: "#entry",
  toggleClass: { targets: "#list", className: "hide" },
});

let mail = false;
ScrollTrigger.create({
  trigger: "#contact",
  onEnter: () => (mail = mail ? true : typewritter()),
  toggleClass: { targets: "#list", className: "hide" },
});

const projects = gsap.utils.toArray(".project");
projects.forEach((project, i) => {
  ScrollTrigger.create({
    start: (i + 1) * innerHeight - 100,
    end: (i + 2) * innerHeight - 100,
    toggleClass: { targets: `#nav_p${i}`, className: "active" },
  });
});

const typewritter = () => {
  let span = document.querySelector("#mail");
  let email = "contact@tomas-b.dev".split("");
  for (let i in email) {
    setTimeout(
      (_) => (span.innerText += email[i]),
      i * 150
    );
  }
  return true;
};
